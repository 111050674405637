// ==========[ TEXT ]==========

h1 {}

h2 {}

.uk-h3,
h3 {
	color       : $black;
	font-size   : 24px;
	font-weight : bold;
	line-height : 28.8px;
	padding: 0;
	margin: 0 0 3px 0;
}

h4 {}

.uk-h5,
h5 {
	color       : $black;
	font-size   : 16px;
	font-weight : bold;
	line-height : 19px;
}

.cms,
p {
	color       : $grey-dark;
	text-align  : left;
	font-size   : 16px;
	font-weight : 300;
	line-height : 24px;

	b {
		font-weight : bold;
	}
}

.uk-text-meta {
	font-weight : 300;
}

// ==========[ LIGHTBOX ]==========
.uk-lightbox {
	background: $white;

	.uk-lightbox-toolbar {
		background: $white;
		color: $black;

		.uk-lightbox-toolbar-icon {
			color: $black;
		}
	}
}

// ==========[ SLIDESHOW ]==========

.uk-slidenav {
	svg {
		color: $grey-light;
	}
}

@media (max-width: 500px) {
	.uk-slideshow-items {
		min-height: inherit !important;
		height: 200px;
		overflow: hidden;

		li {
			overflow: hidden;
			min-height: inherit !important;
		}
	}
}

@media (max-width: 400px) {
	.uk-slideshow-items {
		min-height: inherit !important;
		height: 150px;
		overflow: hidden;

		li {
			overflow: hidden;
			min-height: inherit !important;
		}
	}
}

// ==========[ HEADER ]==========

header {
	.uk-section {
		padding-bottom: 0;
	}

	.uk-navbar {
    //border-bottom : 1px solid $grey-light;
    align-items   : baseline;

    .uk-navbar-left,
    .uk-navbar-right {
    	display        : flex;
    	align-items    : flex-start;
    	flex-direction : column;
    }

    .uk-navbar-nav {
    	li {
    		a {
    			min-height : 0;
    		}
    	}
    }

    .uk-navbar-item {
    	min-height : 35px;
    	padding    : 0;
    }

    .uk-logo {
    	color          : $grey-dark;
    	text-transform : uppercase;
    	font-size      : 24px;
    	line-height    : 28.8px;
    	letter-spacing : 0.1em;

    	span {
    		color       : $black;
    		font-weight : bold;
    	}

    	&.uk-logo-small {
    		font-size : 16px;
    	}

    	&:hover {
    		color : $black;
    	}
    }

    .top-bar {
    	li {
    		a {
    			color       : $grey-dark;
    			font-size   : 14px;
    			font-weight : normal;
    			line-height : 16.8px;

    			&:hover {
    				color : $black;
    			}
    		}

    		&.uk-active {
    			a {
    				color       : $black;
    				font-weight : 700;
    			}
    		}

    		&.divider {
    			width            : 1px;
    			height           : 20px;
    			margin           : 0px 15px;
    			background-color : $grey-dark;
    		}

    		&:last-of-type {
    			a {
    				padding-right : 0;
    			}
    		}

    		&:first-of-type {
    			a {
    				padding-left : 0;
    			}
    		}
    	}
    }

    .bottom-bar {
    	margin-top : 15px;

    	li {
    		padding : 0px 15px;

    		a {
    			padding       : 0;
    			color         : $black;
    			border-bottom : 2px solid transparent;
    			font-size     : 16px;
    			font-weight   : normal;
    			line-height   : 19.2px;
    		}

    		&:first-of-type {
    			padding-left : 0;
    		}

    		&:last-of-type {
    			padding-right : 0;
    		}

    		&.uk-active {
    			a {
    				border-bottom : 2px solid $black;
    				font-weight   : 700;
    				padding: 0 0 2px 0;
    			}
    		}
    	}
    }
}
}

// ==========[ FIXED FOOTER ]==========

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

#wrap {
  min-height: 100%;
}

#main {
  overflow: auto;
  padding-bottom: 100px;
  /* must be same height as the footer */
}


/* Opera Fix thanks to Maleika (Kohoutec) */

body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
  /* thank you Erik J - negate effect of float*/
}

// ==========[ FOOTER ]==========

footer {

	position: relative;
	margin-top: -100px;
	/* negative value of footer height */
	height: 100px;
	clear: both;

	.border-top {
		border-top : 1px solid $grey-light;
	}

	h5 {
		margin      : 0;
		padding     : 0;
		color       : $grey-dark;
		font-size   : 13px;
		font-weight : 100;
		line-height : 19px;

		a {
			color       : $grey-dark;
      //font-weight : bold;

      &:hover {
      	color : $black;
      }
  }
}
}

// ==========[ FORMS ]==========

form.uk-form-stacked {
	label {
		color       : $grey-dark;
		font-size   : 16px;
		font-weight : bold;
		line-height : 24px;
	}

	.uk-form-controls {
		input,
		textarea {
			min-height       : 50px;
			padding-right    : 20px;
			padding-left     : 20px;
			color            : $grey-dark;
			background-color : transparent;
			box-shadow       : inset 0px 0px 0px 1px rgba(229,229,229,1);
			font-size        : 16px;
			font-weight      : 300;
			line-height      : 24px;

			&:focus {
				box-shadow : inset 0px 0px 0px 2px rgba(147,149,152,1);
			}
		}
	}

	.checkbox-label {
		color       : $grey-dark;
		font-weight : 300;

		a {
			color           : $grey-dark;
			text-decoration : underline;

			&:hover {
				color           : $black;
				text-decoration : none;
			}
		}
	}

	.uk-checkbox {
		width            : 30px;
		height           : 30px;
		margin-right     : 20px;
		border           : 1px solid $grey-light;
		background-color : transparent;
	}
}

// ==========[ WYSIWYG ]==========

.wysiwyg-header-image {
	height              : 600px;
	background-repeat   : no-repeat;
	background-position : center;
	background-size     : cover;
}

// ==========[ COMING SOON ]==========

.coming-soon-page {
	h1 {
		color          : $grey-dark;
		text-transform : uppercase;
		font-size      : 24px;
		line-height    : 28.8px;
		letter-spacing : 0.1em;

		span {
			color       : $black;
			font-weight : bold;
		}

		&.uk-logo-small {
			font-size : 16px;
		}

		&:hover {
			color : $black;
		}
	}
}
