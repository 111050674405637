@media
  (max-width : 640px) {
  header {
    .uk-navbar {
      .uk-logo {
        min-height  : 0;
        font-size   : 18px;
        line-height : 24px;

        &.uk-logo-small {
          font-size : 14px;
        }
      }
    }
  }

  .wysiwyg-header-image {
    height : 250px !important;
  }

  .map {
    height : 250px !important;
  }
}

@media
  (max-width : 960px) {
  header {
    .uk-navbar {
      padding-bottom : 15px;
      align-items    : center;
    }
  }

  .image-block,
  .product-block {
    div {
      height : 330px;
    }
  }

  .wysiwyg-header-image {
    height : 400px;
  }

  .map {
    height : 400px;
  }
}
