// ==========[ UTILITY CLASSES ]==========

.bordered {
	border : 1px solid $grey-light;
}

.uk-text-lead {
	color       : $grey-dark;
	font-size   : 16px;
	font-weight : bold;
	line-height : 24px;
}

.uk-height-xlarge {
	height : 600px;
}

// ==========[ BUTTONS ]==========

.btn-back {
	color       : $grey;
	font-size   : 15px;
	font-weight : bold;
	line-height : 24px;

	&:hover {
		color           : $black;
		text-decoration : none;
	}
}

.uk-button-default {
	padding          : 10px 20px;
	color            : $black;
	border           : 1px solid $grey-light;
	background-color : transparent;
	font-size        : 16px;
	font-weight      : bold;
	line-height      : 19px;

	&:hover {
		text-decoration : none;
	}
}

.round-button {
	display          : flex;
	width            : 60px;
	height           : 60px;
	color            : $black;
	border-radius    : 999px;
	background-color : $white;
	align-items      : center;
	justify-content  : center;
	position: absolute;
}

// ==========[ IMAGE BLOCK ]==========

.image-block {
	div {
		background-position : center;
		background-size     : cover;
	}
}

.product-block {
	div {
		border              : 1px solid $grey-light;
		background-repeat   : no-repeat;
		background-position : center;
		background-size     : contain;
	}
}

.image-block,
.product-block {
	display : block;

	div {
		height     : 400px;
		transition : all .1s ease-in-out;
	}

	.content {
		display     : block;
		padding-top : 10px;

		* {
			margin : 0;
		}

		h3 {
			margin-bottom : 3px;
			font-size     : 16px;
			font-weight   : bold;
			line-height   : 19px;
		}

		p {
			color       : $grey;
			font-size   : 14px;
			font-weight : normal;
			line-height : 19px;
		}
	}

	.button {
		display          : flex;
		width            : 60px;
		height           : 60px;
		color            : $black;
		background-color : $white;
		align-items      : center;
		justify-content  : center;
	}

	&:hover {
		text-decoration : none;

		div {
			@include softShadow;
		}
	}
}

// ==========[ BLOCK LIST ]==========

.block-list {
	padding-left : 10px;
	border-left  : 1px solid $grey-light;

	p {
		color : $grey;

		span {
			font-weight : bold;
		}
	}
}

// ==========[ SHARING ]==========

.sharing {
	display         : flex;
	align-items     : center;
	justify-content : flex-start;

	h5 {
		margin-right : 10px;
	}

	a {
		margin : 0 4px;
		color  : $black;

		&:hover {
			color : $grey-dark;
		}

		&:first-of-type {
			margin-left : 0;
		}

		&:last-of-type {
			margin-right : 0;
		}
	}
}

// ==========[ SOCIALS ]==========

.social {
	color : $grey-dark;

	&:hover {
		color           : $black;
		text-decoration : none;
	}
}

// ==========[ MAP ]==========

.map {
	width  : 100%;
	height : 600px;
}
