// ==========[ COLORS ]==========

$white      : #FFFFFF;
$grey-light : #E5E5E5;
$grey       : #C3C3C3;
$grey-dark  : #939598;
$black      : #000000;

// ==========[ MIXINS ]==========

@mixin softShadow {
  box-shadow : 0 14px 25px rgba(0,0,0,.16);
}
